import React from "react"
import { Container } from "react-bootstrap"
import { Link } from "gatsby"

const ApplyForFunding = () => {
  return (
    <Container fluid className="apply-for-funding-wrapper">
      <Container
        data-sal="fade"
        data-sal-delay="0"
        data-sal-easing="ease"
        data-sal-duration="1000"
        className="content-container"
      >
        <div className="content-image"></div>
        <div className="content">
          <h3>
            Our approach is to partner with entrepreneurs who are at the
            forefront of business innovation to build sustainable ventures
          </h3>
          <Link to="/apply-for-funding" className="btn btn-white">
            Apply For Funding
          </Link>
        </div>
      </Container>
    </Container>
  )
}

export default ApplyForFunding
