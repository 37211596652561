import { Link, navigate } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Navbar, Nav, Button } from "react-bootstrap"

import Image from "@components/common/image"

const Header = ({ siteTitle }) => {
  const [mobileToggle, setMobileToggle] = useState(false)

  return (
    <header
      data-sal="fade" 
      data-sal-delay="300"
      data-sal-easing="ease"
      data-sal-duration="1000"
      className="container p-x-30"
    >
      <Navbar expand="lg">
        <Link className="navbar-brand mr-auto" to="/">
          <Image src="al-falaj-logo.png" alt={siteTitle} />
        </Link>

        <div className="mobile-action">
          <Button
            variant="outline-gray"
            onClick={() => navigate("/apply-for-funding/")}
          >
            Apply For Funding
          </Button>
          <div
            className="navbar-toggle"
            onClick={() => {
              setMobileToggle(!mobileToggle)
            }}
          >
            <Image src="toggler-icon.png" alt={"menu"} />
          </div>
        </div>
        <div className={mobileToggle ? "mobile-menu in" : "mobile-menu"}>
          <div className="mobile-body">
            <div className="mobile-brand">
              <Link to="/">
                <Image src="al-falaj-logo-2.png" alt={siteTitle} />
              </Link>
              <div
                className="mobile-cancel"
                onClick={() => {
                  setMobileToggle(!mobileToggle)
                }}
              >
                <Image src="cancel-icon.png" />
              </div>
            </div>
            <ul className="mobile-nav">
              <li>
                <Link to="/who-we-are/">Who We Are</Link>
              </li>
              <li>
                <Link to="/our-portfolio/">Our Portfolio</Link>
              </li>
              <li>
                <Link to="/investment-philosophy/">Investment Philosophy</Link>
              </li>
              <li>
                <Link to="/apply-for-funding/">Apply for funding</Link>
              </li>
            </ul>
          </div>
          <div className="mobile-footer">
            <div className="follow">Follow us</div>
            <ul className="social">
              <li>
                <Link to="/">
                  <Image src="facebook-icon.png" alt={"facebook"} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <Image src="linkedin-icon.png" alt={"linkedin"} />
                </Link>
              </li>
              <li>
                <Link to="/">
                  <Image src="google-icon.png" alt={"google"} />
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <Nav.Item>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/who-we-are/"
              >
                Who We Are
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/our-portfolio/"
              >
                Our Portfolio
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                className="nav-link"
                activeClassName="active"
                to="/investment-philosophy/"
              >
                Investment Philosophy
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Button
                variant="outline-gray"
                onClick={() => navigate("/apply-for-funding/")}
              >
                Apply For Funding
              </Button>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
