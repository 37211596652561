import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"

import Image from "@components/common/image"

// © {new Date().getFullYear()}, Built with
// {` `}
// <a href="https://www.gatsbyjs.com">Gatsby</a>
const Footer = ({ siteTitle }) => (
  <footer>
    <Container>
      <div className="footer-body">
        <div
          className="footer-brand"
          data-sal="slide-left"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          <Link to="/">
            <Image src="al-falaj-logo-2.png" alt={siteTitle} />
          </Link>
        </div>

        <ul className="footer-nav">
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/who-we-are/">Who We Are</Link>
          </li>
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/our-portfolio/">Our Portfolio</Link>
          </li>
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/investment-philosophy/">Investment Philosophy</Link>
          </li>
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/apply-for-funding/">Apply for funding</Link>
          </li>
        </ul>
      </div>
    </Container>
    <div className="footer-footer">
      <Container className="d-flex justify-content-between align-items-center flex-column flex-lg-row">
        <div
          className="follow order-1 order-lg-0"
          data-sal="fade"
          data-sal-delay="0"
          data-sal-easing="ease"
          data-sal-duration="1000"
        >
          © {new Date().getFullYear()} Al Falaj, All rights reserved.
        </div>
        <ul className="social order-0 order-lg-1">
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/">
              <Image src="facebook-icon.png" alt={"facebook"} />
            </Link>
          </li>
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/">
              <Image src="linkedin-icon.png" alt={"linkedin"} />
            </Link>
          </li>
          <li
            data-sal="fade"
            data-sal-delay="0"
            data-sal-easing="ease"
            data-sal-duration="1000"
          >
            <Link to="/">
              <Image src="google-icon.png" alt={"google"} />
            </Link>
          </li>
        </ul>
      </Container>
    </div>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
